import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, ButtonGroup, styled } from '@mui/material';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfWeek,
  format,
  isSameDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import skLocale from 'date-fns/locale/sk/index.js';
import { upperFirst } from 'lodash-es';
import React, { useMemo } from 'react';

// import styles from './MonthCalendar.scss';

export interface Event {
  start: Date;
  end: Date;
  title: string | React.ReactNode;
  id: string;
}

interface OwnProps {
  className?: string;
  month: Date;
  events: Event[];
  draggingEvent: Event | null;

  onMonthChange(month: Date);
  onPrevMonthMouseOver?();
  onNextMonthMouseOver?();
}

type Props = OwnProps;

const MonthCalendar: React.FunctionComponent<Props> = ({
  className,
  month = new Date(),
  onMonthChange,
  events,
  draggingEvent,
  onNextMonthMouseOver,
  onPrevMonthMouseOver,
}) => {
  const weeks = useMemo(() => {
    const ret: React.ReactNode[] = [];
    let done = false;
    let start = startOfWeek(startOfMonth(month), { locale: skLocale });
    let monthIndex = start.getMonth();
    let count = 0;

    while (!done) {
      // Calculate events for this week
      const end = endOfWeek(start, { locale: skLocale });
      const weekEvents = events.filter(
        (event) => (event.start >= start && event.start <= end) || (event.end >= start && event.end <= end),
      );

      ret.push(
        <Week
          key={start.getTime()}
          currentMonth={month}
          start={start}
          events={weekEvents}
          draggingEvent={draggingEvent}
        />,
      );

      start = addWeeks(start, 1);
      done = count++ > 2 && monthIndex !== start.getMonth();
      monthIndex = start.getMonth();
    }

    return ret;
  }, [month, events, draggingEvent]);

  const onPrevMonthClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newDate = subMonths(month, 1);
    onMonthChange(newDate);
  });

  const onNextMonthClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newDate = addMonths(month, 1);
    onMonthChange(newDate);
  });

  const onTodayClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newDate = new Date();
    onMonthChange(newDate);
  });

  return (
    <div>
      <Box mb={4} position="relative">
        <Box position="absolute" display="flex" top={0} bottom={0} alignItems="center">
          <ButtonGroup>
            <Button
              size="small"
              onClick={onPrevMonthClick}
              onMouseOver={onPrevMonthMouseOver}
              sx={{
                color: 'inherit',
                borderColor: '#e7eaec',
              }}
            >
              <ChevronLeft />
            </Button>
            <Button
              size="small"
              onClick={onNextMonthClick}
              onMouseOver={onNextMonthMouseOver}
              sx={{
                color: 'inherit',
                borderColor: '#e7eaec',
              }}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>

          <Button
            variant="outlined"
            size="small"
            onClick={onTodayClick}
            sx={{ ml: 2, color: 'inherit', borderColor: '#e7eaec' }}
          >
            Dnes
          </Button>
        </Box>

        <Box fontSize="2em" textAlign="center" fontWeight="300">
          {upperFirst(format(month, 'LLLL yyyy', { locale: skLocale }))}
        </Box>
      </Box>

      <Box borderTop={1} borderLeft={1} borderBottom={1} borderColor="#e7eaec">
        <DayNames />
        {weeks}
      </Box>
    </div>
  );
};

MonthCalendar.displayName = 'MonthCalendar';

export default React.memo<Props>(MonthCalendar);

interface WeekOwnProps {
  currentMonth: Date;
  start: Date;
  events: Event[];
  draggingEvent: Event | null;
}

const Week: React.FunctionComponent<WeekOwnProps> = ({ currentMonth, start, events, draggingEvent }) => {
  const days = useMemo(() => {
    const ret: React.ReactNode[] = [];
    let current = start;
    for (let i = 0; i < 7; i++) {
      const end = endOfDay(current);
      const dayEvents = events.filter(
        (event) => (event.start >= current && event.start <= end) || (event.end >= current && event.end <= end),
      );

      ret.push(
        <Day
          key={current.getTime()}
          date={current}
          isCurrentMonth={current.getMonth() === currentMonth.getMonth()}
          events={dayEvents}
          draggingEvent={draggingEvent}
        />,
      );
      current = addDays(current, 1);
    }
    return ret;
  }, [events, start, currentMonth, draggingEvent]);

  return (
    <Box display="flex" borderTop={1} borderColor="#e7eaec">
      {days}
    </Box>
  );
};

interface DayOwnProps {
  date: Date;
  isCurrentMonth: boolean;
  events: Event[];
  draggingEvent: Event | null;
}

const Day: React.FunctionComponent<DayOwnProps> = ({ date, isCurrentMonth, events, draggingEvent }) => {
  const sortedEvents = useMemo(() => {
    return [...events].sort((a, b) => a.start.getTime() - b.start.getTime());
  }, [events]);

  const isDropDisabled =
    draggingEvent === null ||
    (draggingEvent.start.getTime() === draggingEvent.end.getTime() && isSameDay(draggingEvent.start, date));

  return (
    <Droppable droppableId={date.toISOString()} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <Box
          width={`${(100 / 7).toFixed(3)}%`}
          borderRight={1}
          borderColor="#e7eaec"
          sx={{
            backgroundColor: snapshot.isDraggingOver ? '#ffe0cf' : isSameDay(new Date(), date) ? '#fef5ec' : undefined,
            '&::before': {
              content: '" "',
              float: 'left',
              paddingTop: '100%',
            },
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <Box textAlign="right" pr={1} color={!isCurrentMonth ? '#ced0d1' : undefined} sx={{ userSelect: 'none' }}>
            {date.getDate()}
          </Box>
          <Box>
            {sortedEvents.map((event) => (
              <React.Fragment key={event.id}>{event.title}</React.Fragment>
            ))}
            {provided.placeholder}
          </Box>
        </Box>
      )}
    </Droppable>
  );
};

interface DayNamesOwnProps {}

const DayName = styled('div')(({ theme }) => ({
  width: `${(100 / 7).toFixed(3)}%`,
  borderRight: `1px solid #e7eaec`,
  textAlign: 'center',
  padding: theme.spacing(1),
  fontWeight: 'bold',
}));

const DayNames: React.FunctionComponent<DayNamesOwnProps> = () => {
  return (
    <Box display="flex">
      <DayName>Pondelok</DayName>
      <DayName>Utorok</DayName>
      <DayName>Streda</DayName>
      <DayName>Štvrtok</DayName>
      <DayName>Piatok</DayName>
      <DayName>Sobota</DayName>
      <DayName>Nedeľa</DayName>
    </Box>
  );
};

interface CalendarEventOwnProps {
  className?: string;
  id: string;
  index: number;
  isDragDisabled?: boolean;
  children: React.ReactNode;
}

// Disable reordering animations
function getStyle(style, snapshot) {
  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
}

let CalendarEvent: React.FunctionComponent<CalendarEventOwnProps> = ({
  id,
  index,
  isDragDisabled = false,
  children,
}) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <Box
          sx={{ userSelect: 'none', cursor: 'grab' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          <Box
            mx={1}
            mb={1}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            sx={{
              transition: 'transform .1s',
              transform: snapshot.isDragging ? 'rotateZ(10deg)' : undefined,
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

CalendarEvent.displayName = 'CalendarEvent';

CalendarEvent = React.memo<CalendarEventOwnProps>(CalendarEvent);

export { CalendarEvent };
