import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import MonthCalendar, { CalendarEvent, Event } from '@asaprint/asap/components/MonthCalendar.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authenticated from '@asaprint/asap/decorators/authenticated.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import {
  PlanningAssembly_Load,
  PlanningAssembly_Save,
} from '@asaprint/asap/routes/__authenticated/planning/assembly.graphql';
import {
  PlanningAssembly_LoadQuery,
  PlanningAssembly_LoadQueryVariables,
  PlanningAssembly_OrderReceivedFragment,
  PlanningAssembly_SaveMutation,
  PlanningAssembly_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { isAssemblyFinished } from '@asaprint/common/helpers/OrderReceived.js';
import Link from '@engined/client/components/Link.js';
import Loading from '@engined/client/components/Loading.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, DragDropContextProps, Droppable } from '@hello-pangea/dnd';
import { Box, Grid } from '@mui/material';
import { addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import skLocale from 'date-fns/locale/sk/index.js';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';

const logger = getLogger('components/pages/planning/PlanningAssembly');

const NOT_PLANNED = 'not-planned';

interface OrderPlaceholderOwnProps {
  type: string;
  order: PlanningAssembly_OrderReceivedFragment;
}

let OrderPlaceholder: React.FunctionComponent<OrderPlaceholderOwnProps> = ({ type, order }) => (
  <Box
    py={1}
    px={2}
    color="#fff"
    borderRadius="4px"
    sx={{
      backgroundColor: (theme) => (isAssemblyFinished(order) ? '#5cbc72' : theme.palette.primary.main),
    }}
  >
    {type === 'day' ? (
      <Link fontSize="0.8em" color="#fff" to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: order.id })}>
        {order.moneyAddressName || order.moneyAddressContactName}
      </Link>
    ) : (
      <>
        <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" fontWeight="bold" fontSize="0.9em">
          <Link to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: order.id })} color="#fff">
            {order.moneyAddressName || order.moneyAddressContactName}
          </Link>
        </Box>
        <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" fontWeight="400" fontSize="0.7em">
          <Link to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id: order.id })} color="#fff">
            {order.moneyName}
          </Link>
        </Box>
      </>
    )}
  </Box>
);

OrderPlaceholder.displayName = 'OrderPlaceholder';

OrderPlaceholder = React.memo<OrderPlaceholderOwnProps>(OrderPlaceholder);

interface OwnProps {}

type Props = OwnProps;

const Assembly: React.FunctionComponent<Props> = () => {
  const [month, setMonth] = useState<Date>(new Date());
  const [draggingOrder, setDraggingOrder] = useState<PlanningAssembly_OrderReceivedFragment>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error, client } = useQuery<PlanningAssembly_LoadQuery, PlanningAssembly_LoadQueryVariables>(
    PlanningAssembly_Load,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        assemblyAtStart: startOfWeek(startOfMonth(month), { locale: skLocale }).toISOString(),
        assemblyAtEnd: endOfWeek(endOfMonth(month), { locale: skLocale }).toISOString(),
      },
    },
  );

  const ordersReceived = data?.ordersReceived;
  const notPlannedOrders = useMemo<PlanningAssembly_OrderReceivedFragment[]>(() => {
    if (!ordersReceived?.edges.length) {
      return [];
    }

    return ordersReceived.edges.filter((edge) => edge.node.assemblyAt === null).map((edge) => edge.node);
  }, [ordersReceived]);

  const plannedOrders = useMemo<PlanningAssembly_OrderReceivedFragment[]>(() => {
    if (!ordersReceived?.edges.length) {
      return [];
    }

    return ordersReceived.edges.filter((edge) => edge.node.assemblyAt).map((edge) => edge.node);
  }, [ordersReceived]);

  const plannedOrdersEvents = useMemo<Event[]>(() => {
    return plannedOrders.map((o, index) => {
      return {
        start: new Date(o.assemblyAt),
        end: new Date(o.assemblyAt),
        id: o.id,
        title: (
          <CalendarEvent id={o.id} index={index} isDragDisabled={isAssemblyFinished(o)}>
            <OrderPlaceholder type="day" order={o} />
          </CalendarEvent>
        ),
      };
    });
  }, [plannedOrders]);

  const [saveExecute] = useMutation<PlanningAssembly_SaveMutation, PlanningAssembly_SaveMutationVariables>(
    PlanningAssembly_Save,
  );

  const onDragStart = useEventCallback<DragDropContextProps['onDragStart']>(({ source, draggableId }) => {
    setDraggingOrder(ordersReceived.edges.map((e) => e.node).find((o) => o.id === draggableId));
  });

  const onDragEnd = useEventCallback<DragDropContextProps['onDragEnd']>(({ source, destination, draggableId }) => {
    setDraggingOrder(null);

    // Drop outside of droppable
    if (!destination) {
      return;
    }

    // Nothing has moved
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const orderReceived = ordersReceived.edges.find((edge) => edge.node.id === draggableId)?.node;
    if (!orderReceived) {
      return;
    }

    const execute = async () => {
      try {
        await saveExecute({
          variables: {
            id: orderReceived.id,
            updatedAt: orderReceived.updatedAt,
            assemblyAt: destination.droppableId === NOT_PLANNED ? null : destination.droppableId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            orderReceivedSave: {
              ...orderReceived,
              assemblyAt: destination.droppableId === NOT_PLANNED ? null : destination.droppableId,
            },
          },
        });
      } catch (err) {
        displayError(err, enqueueSnackbar, logger);
      }
    };

    execute();
  });

  const onPrevMonthMouseOver = useEventCallback(() => {
    const prevMonth = subMonths(month, 1);
    client.query({
      query: PlanningAssembly_Load,
      variables: {
        assemblyAtStart: startOfWeek(startOfMonth(prevMonth), { locale: skLocale }).toISOString(),
        assemblyAtEnd: endOfWeek(endOfMonth(prevMonth), { locale: skLocale }).toISOString(),
      },
    });
  });

  const onNextMonthMouseOver = useEventCallback(() => {
    const nextMonth = addMonths(month, 1);
    client.query({
      query: PlanningAssembly_Load,
      variables: {
        assemblyAtStart: startOfWeek(startOfMonth(nextMonth), { locale: skLocale }).toISOString(),
        assemblyAtEnd: endOfWeek(endOfMonth(nextMonth), { locale: skLocale }).toISOString(),
      },
    });
  });

  const draggingEvent: Event = useMemo((): Event => {
    if (!draggingOrder) {
      return null;
    }

    return {
      start: new Date(draggingOrder.assemblyAt),
      end: new Date(draggingOrder.assemblyAt),
      id: draggingOrder.id,
      title: (
        <CalendarEvent id={draggingOrder.id} index={-1}>
          <OrderPlaceholder type="day" order={draggingOrder} />
        </CalendarEvent>
      ),
    };
  }, [draggingOrder]);

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faWrench} /> Plánovanie montáže
          </>
        }
      />

      {error ? (
        <Loading error={error} />
      ) : (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <NotPlannedOrders orders={notPlannedOrders} draggingOrder={draggingOrder} />
            </Grid>
            <Grid item xs={12} md={9}>
              <PagePaper title="Kalendár montáži">
                <MonthCalendar
                  month={month}
                  onMonthChange={setMonth}
                  events={plannedOrdersEvents}
                  draggingEvent={draggingEvent}
                  onPrevMonthMouseOver={onPrevMonthMouseOver}
                  onNextMonthMouseOver={onNextMonthMouseOver}
                />
              </PagePaper>
            </Grid>
          </Grid>
        </DragDropContext>
      )}
    </>
  );
};

export default authenticated()(Assembly);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const today = new Date();
    const result = await apollo.query({
      query: PlanningAssembly_Load,
      variables: {
        assemblyAtStart: startOfWeek(startOfMonth(today), { locale: skLocale }).toISOString(),
        assemblyAtEnd: endOfWeek(endOfMonth(today), { locale: skLocale }).toISOString(),
      },
    });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Plánovanie',
    },
    {
      text: 'Plánovanie montáže',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Plánovanie montáže | ${meta.title}`,
  }),
};

interface NotPlannedOrdersOwnProps {
  orders: PlanningAssembly_OrderReceivedFragment[];
  draggingOrder: PlanningAssembly_OrderReceivedFragment | null;
}

let NotPlannedOrders: React.FunctionComponent<NotPlannedOrdersOwnProps> = ({ orders, draggingOrder }) => {
  return (
    <PagePaper title="Nedokončené objednávky">
      <Droppable droppableId={NOT_PLANNED} isDropDisabled={!draggingOrder?.assemblyAt}>
        {(provided, snapshot) => (
          <Box
            p={2}
            sx={{
              background: snapshot.isDraggingOver ? '#ffe0cf' : undefined,
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {orders.map((order, index) => (
              <NotPlannedOrder key={order.id} order={order} index={index} />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </PagePaper>
  );
};

NotPlannedOrders.displayName = 'NotPlannedOrders';

NotPlannedOrders = React.memo<NotPlannedOrdersOwnProps>(NotPlannedOrders);

let NotPlannedOrder: React.FC<{ order: PlanningAssembly_OrderReceivedFragment; index: number }> = ({
  order,
  index,
}) => (
  <CalendarEvent id={order.id} index={index}>
    <OrderPlaceholder type="not-planned" order={order} />
  </CalendarEvent>
);

NotPlannedOrder.displayName = 'NotPlannedOrder';

NotPlannedOrder = React.memo(NotPlannedOrder);
